:root {
    --first-color: '#7dfcdb';
    --second-color: '#F4F4F4';
    --third-color: '#64ffda';
    --fourth-color: '#c4c6c8';

    --font-mono: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
}

html {
    scroll-behavior: smooth;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #131a24;
    display: flex;
    justify-content: space-between;
    height: 75px;
    align-items: center;
    z-index: 999;
}

nav a {
    display: inline-block;
    text-decoration: none;
    color: #fff;

}



.Logo {
    color: #64ffda;
    margin: 10px;
    font-family: "Space Grotesk Bold", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 700;
    font-size: 25px;
}

.menueIconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 70px;
    font-size: 50px;
    border: none;
    background-color: transparent;
}

.menueIcon {
    color: #67cee2 !important;
}

.sideMenue {
    position: fixed;
    top: 75px;
    left: 0;
    width: 290px;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    backdrop-filter: blur(7px);
}

.visible {
    transform: translateX(0);
}

.navMenueLogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.popMenue {
    margin: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.navLinks {
    margin: 10px;
    padding: 10px;
}

.menue {
    display: none;
}

.navItems {
    font-family: "Roboto mono";
}

.navItem {
    display: flex;
    list-style-type: none;
    font-family: SF Mono, Fira Code, Fira Mono, Roboto Mono, monospace;
    font-size: 20px !important;
}

.navItemSmallScreen {
    list-style-type: none;
    font-family: SF Mono, Fira Code, Fira Mono, Roboto Mono, monospace;
    font-size: 15px !important;
}

.itemNum {
    color: #31f5c7;
    font-family: SF Mono, Fira Code, Fira Mono, Roboto Mono, monospace;
    /* font-weight: bold; */
    font-size: 15px;
    width: auto;
    top: 1.25em;
    position: absolute;
    line-height: .1em;
    display: inline-block;
    height: auto;
    opacity: .75;
    padding-left: 30px;
    /* need to make it more professional */
}

/* Rusume button */
.resume {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 39px;
    width: 82px;
    margin: 10px;
    margin-right: 15px;
    border: 1px solid #31f5c7;
    border-radius: 4px;
    font-family: var(--font-mono);
    z-index: 5;
}

.resume:hover {
    cursor: pointer;
}

.MenueItems {
    color: #7dfcdb;
}

@media (max-width: 640px) {
    nav {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: #131a24;
        display: flex;
        justify-content: space-between !important;
        height: 75px;
        align-items: center;
        z-index: 5;
    }

    nav a {
        font-family: "JetBrains Mono", monospace;
        color: #1f3e60;
    }

    .navItems {
        display: none;
    }


    .menue {
        color: #fff;
        display: flex;
        justify-content: flex-start;
        margin: 5px;

    }

    .LogoSmall {
        color: #64ffda;
        margin: 0;
        font-family: "Space Grotesk Bold", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 600;
        font-size: 16px;
    }

    .logo {
        display: none;
    }

    code {
        margin: 0;
    }

    #resumeButton {
        color: #64ffda;

    }
}