.EmailSection {
    position: fixed;
    height: 100vh;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
}

.emailLine {
    height: 111px;
    border-right: 3px solid #f4f4f49d;
    margin-top: 150px;
    bottom: 0;
}

.email a {
    color: #f4f4f49d;
    text-decoration: none;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono',monospace;
    line-height: 18px;
    letter-spacing: 0.2em;
}



.email {
    rotate: 90deg;

}

.email  :hover {
    color: aqua;
}