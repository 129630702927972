.social {
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.socialLinks {
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialLinks ul {
    margin: 0;
    padding: 0;
}

.socialLinks li {
    list-style: none;
    text-decoration: none;
    margin: 15px;
    padding: 6px;
}

.icon {
    color: #f4f4f49d;
    font-size: 30px;
}


.icon:hover {
    color: aqua;
}

.line {
    height: 111px;
    border-right: 3px solid #f4f4f49d;
}