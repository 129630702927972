:root{
  /* animation-delay: 0.2s; */
}
body {
    --sb-track-color: #0d1c31;
    --sb-thumb-color: #57b479;
    --sb-size: 3px;
  
    scrollbar-color: var(--sb-thumb-color) 
                     var(--sb-track-color);
  }
  
  body::-webkit-scrollbar {
    width: var(--sb-size) 
  }
  
  body::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 3px;
  }
  
  body::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 3px;
    
    }