footer {
    bottom: 0;

}

footer a {
    height: 80px;
    width: 100%;
    bottom: 0;
    text-decoration: none;
    font-size: 14px;
    color: #7dfcdb;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footerIcons span {
    margin: 5px;
    font-size: 1rem;
}

@media (max-width: 640px) {
    footer a {
        font-size: 10px;
    }
}