.parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactSpan {
    color: #52d1a9;
    font-size: 16px;
    font-weight: 400;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'monospace';
}

.smallSpan {
    padding-right: 10px;
}

.parent p {
    margin-bottom: 30px;
    display: block;
    max-width: 550px;
    font-weight: bold;
    font-size: 17px;
    line-height: 1.6;
}

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid #8892b0;
    color: #8892b0;
    font-size: 35px;
}

.social a {
    color: #8892b0;
    text-decoration: none;
}

.social:hover {
    background-color: #8892b0c7;
    color: #edeff5;
}

.social a:hover {
    color: #edeff5;
}