.mainContent {
    display: flex;
    justify-content: space-between;
}

.socialComponent {
    width: 10%;
    display: flex;
    justify-content: center;
}

.meddleComponent {
    width: 80%;
}

.emailComponent {
    width: 10%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}



.About {
    /* height: 610px; */
    margin-top: 5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    
}

.experience {
    margin-top: 5rem;

    /* height: 610px; */
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.projects {
    margin-top: 5rem;

    /* height: 610px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 525px; */
}
/* small screens */
@media (max-width: 640px) {
    .emailComponent {
        width: 10%;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }
    .socialComponent {
        height: 100%;
        width: 14%;
        display: flex;
        justify-content: center;
    }
    
}