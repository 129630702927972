.smalltextSpan {
  color: #52d1a9;
  font-size: 16px;
  font-weight: 400;
  font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'monospace';
}

.smallSpan {
  padding-right: 10px;
}

.parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.experience {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 2px solid #ffffffb7;
}

.first,
.second,
.third {
  height: auto;
  width: calc(32% - 20px);
  border: 1px solid #ffffff65;
  padding: 15px;
}

@media (max-width: 980px) {
  .first,
  .second,
  .third {
      width: calc(48% - 20px);
      margin-bottom: 20px; /* Add some space between items for better readability */
  }
}

@media (max-width: 640px) {
  .first,
  .second,
  .third {
      width: 100%;
  }
}
