.title {
    margin: 20px;
    font-size: 18px;
    font-weight: 300;
    color: #7DFCDB;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.smallTitle {
    top: 10px;
    margin-right: 10px;
}

.projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.project {
    background-color: #223550;
    border-radius: 0.4rem;
    padding: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Add these to your CSS */
.project[data-has-link="true"] {
    cursor: pointer;
    transition: all 0.3s ease;
}

.project a {
    position: relative;
    /* Ensure links stay clickable */
    z-index: 1;
}

.project::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

/* Add this to maintain clickable area */
.project {
    position: relative;
}

.project:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.projectNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 33px;
}

.folderIcon {
    color: #7DFCDB;
    font-size: 2.5rem;
}

.longProjectTitle {
    color: #7DFCDB;
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0.5rem 0;
    flex-grow: 1;
    text-align: left;
    padding-left: 1rem;
}

.twoIcons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.icon a {
    color: #7DFCDB;
    transition: color 0.3s ease;
    font-size: 1.5rem;
}

.icon a {
    text-decoration: none;
    color: #7DFCDB;
    font-size: 2rem;
}

.icon a:hover {
    color: #64bba4;
}



.externalLinkIcon a {
    text-decoration: none;
    color: #7DFCDB;
    font-size: 2rem;
}


.ProjectTitle {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    color: #7DFCDB;
    font-size: 19px;
    font-weight: 700;
}

.longProjectTitle {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    color: #7DFCDB;
    font-size: 16px;
    font-weight: 700;
}

.twoIcons {
    width: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projectDes {
    margin-bottom: auto;
}

.des {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #a8b2d1;
    margin-bottom: 1.5rem;
}

.projectFooter {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
}

.skillSpan {
    display: inline-block;
    margin: 0.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 3px;
    background-color: #0a152465;
    color: #64bba4;
    font-size: 1rem;
    font-family: monospace;
}

@media (max-width: 768px) {
    .projects {
        grid-template-columns: 1fr;
        padding: 1rem;
    }

    .project {
        min-height: auto;
    }

    .projectNav {
        flex-wrap: wrap;
        gap: 1rem;
    }

    .longProjectTitle {
        font-size: 1.1rem;
        padding-left: 0;
    }
}

@media (max-width: 480px) {
    .title {
        flex-direction: column;
        text-align: center;
    }

    .des {
        font-size: 0.9rem;
    }
}