.smallText {
  margin: 133px 0px 30px 4px;
  color: #64FFDA;
  font-family: var(--font-mono);
  font-size: 16px;
  font-weight: 400;
}

.nhussein {
  margin: 0;
  color: #f4f4f4;
  font-family: Calibre, Inter, "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
  font-size: 66px;
}

.heading {
  margin: 0;
  color: #8892b0;
  font-family: Calibre, Inter, "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
  font-size: 60px;
}

.intro {
  margin: 0;
  color: #8892b0;
  font-family: Calibre, Inter, "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
  font-size: 20px;
  max-width: 820px;
}
.intro p {
  line-height: 1.6;
}

@media (max-width: 640px) {
  .nhussein {
    margin: 0;
    color: #f4f4f4;
    font-family: Calibre, Inter, "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
    font-size: 35px;
  }

  .heading {
    margin: 0;
    color: #8892b0;
    font-family: Calibre, Inter, "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
    font-size: 25px;
  }
  .intro {
    margin: 0;
    color: #8892b0;
    font-family: Calibre, Inter, "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
    font-size: 15px;
    max-width: 820px;
  }
}